export const menuItems = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "  Mortgage Programs",
    submenu: [ 
      {
        title: "FHA",
        path: "/mortgage-program/fha-loans",
      },
      {
        title: "VA",
        path: "/mortgage-program/va-loans",
      },
      {
        title: "Conventional",
        path: "/mortgage-program/conventional",
      },
      {
        title: "USDA",
        path: "/mortgage-program/usda",
      },
      {
        title: "Jumbo",
        path: "/mortgage-program/jumbo-loans",
      },
    ],
  },
    {
      title: "Non-QM",
      submenu: [ 
      {
        title: "DSCR Loans",
        path: "/mortgage-program/dscr",
      },
      {
        title: "Bank Statement Loans",
        path: "/mortgage-program/bank-statement-loans",
      },
      {
        title: "1099",
        path: "/mortgage-program/1099-loans",
      },
      {
        title: "P&L Loan",
        path: "/mortgage-program/p-l-loans",
      },
      {
        title: "Asset Utilization",
        path: "/mortgage-program/asset-utilization",
      },
      {
        title: "No Income/Ratio Loan",
        path: "/mortgage-program/no-income-loan",
      },
      {
        title: "Foreign National",
        path: "/mortgage-program/foreign-national",
      },
      {
        title: "ITIN Loans",
        path: "/mortgage-program/itin-loans",
      },
      ]
      },
  {
    title: "Commercial",
    submenu: [
      {
        title: "Residential Construction Loans",
        path: "/mortgage-program/residential-construction-loans",
      },

      {
        title: "Rental Building Purchases",
        path: "/mortgage-program/rental-building-purchase",
      },

      {
        title: "Plaza, Retail & Industrial Fiancing",
        path: "/mortgage-program/plaza-retail-industrial-financing",
      }
    ] 
  },
  {
    title: "Mortgage Calculator",
    path: "/calculator", 
  }
];
