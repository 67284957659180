import React, { useEffect } from "react";
import './new-blog-detail.scss';
import BannerInner from "../../../../components/shared/banner-inner/BannerInner";
import bannerInnerImg from "../../../../assets/public/images/lender-bg.jpg";
import logo from "../../../../assets/public/images/logo-desjardins.png";
import { useParams as params } from "react-router-dom";
import { posts } from "../../../../config/posts";
import { Container } from "@mui/material";
import { Button } from "@mui/material";
// import { Link } from "@mui/material";
import { Markup } from 'react-render-markup';
import { useNavigate ,Link} from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs'; 
// import {} from 'react-router-dom'
import Helmet from 'react-helmet';
const NewBlogDetail = function () {
    const { post_slug } = params();
    const postFiltered = posts.find(post => String(post.slug) === post_slug);

    const [insuredThreeYearFixed, setInsuredThreeYearFixed] = React.useState([]);
    const [insuredFourYearFixed, setInsuredFourYearFixed] = React.useState([]);
    const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState([]);
    const [insuredFiveYearVariable, setInsuredFiveYearVariable] = React.useState([]);
    const [insuredOneYearFixed, setInsuredOneYearVariable] = React.useState([]);
    const [insuredTwoYearFixed, setInsuredTwoYearVariable] = React.useState([]);

    const [buyDownLender, setBuyDownLender] = React.useState([]);
    const [buyDownTheirRates, setBuyDownTheirRates] = React.useState([]);
    const [buyDownOurRates, setBuyDownOurRates] = React.useState([]);
    const [buyDownOurSaving, setBuyDownOurSaving] = React.useState([]);
    const navigate = useNavigate();
    const routeChange = () =>{ 
        let path = `/apply`; 
        navigate(path);
      }
    useEffect(() => {
        const token = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        };
        // const apiData = {...values, ...slugObj};
        // console.log('valuesss', apiData);
        // setShowLoader(true);
        fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                  
                    const insuredOneYearFixed = Math.min(...JSON.parse(result.rates.insured_one_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredTwoYearFixed = Math.min(...JSON.parse(result.rates.insured_two_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredThreeYearFixed = Math.min(...JSON.parse(result.rates.insured_three_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFourYearFixed = Math.min(...JSON.parse(result.rates.insured_four_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                        return el != '';
                      }));

                    setBuyDownLender(JSON.parse(result.buyDownRates.lender));
                    setBuyDownTheirRates(JSON.parse(result.buyDownRates.their_rates));
                    setBuyDownOurRates(JSON.parse(result.buyDownRates.our_rates));
                    setBuyDownOurSaving(JSON.parse(result.buyDownRates.your_savings));

                    setInsuredThreeYearFixed(insuredThreeYearFixed);
                    setInsuredFourYearFixed(insuredFourYearFixed);
                    setInsuredFiveYearFixed(insuredFiveYearFixed)
                    setInsuredFiveYearVariable(insuredFiveYearVariable);
                    setInsuredOneYearVariable(insuredOneYearFixed);
                    setInsuredTwoYearVariable(insuredTwoYearFixed);

                }
            )

    }, []); 
    const formatText = (text) => {
        return text
          .split('-') // Split the text by hyphens
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(' '); // Join the words with spaces
      };
      const formattedPostSlug = formatText(post_slug);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{postFiltered?.meta_title}</title>
                <meta name='description' content={postFiltered?.meta_description} />
                <meta name='keywords' content={postFiltered?.keywords} />
            </Helmet>
            <BannerInner image={bannerInnerImg} heading={"Canada's Largest lender network"} subHeading={postFiltered.title} logo={postFiltered.logo} personImg={postFiltered.personImg} />
            <section className="blog-content">
                <Container> <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>  
                <Typography color="text.primary">{formattedPostSlug}</Typography>
            </Breadcrumbs>
                    <div className="blog-content-wrap sec-padding">
                        <div className="blog-text">
                            <Markup markup={postFiltered.description} />
                        </div>
                        <div className="blog-sidebar">
                            <div className="get-best-rate">
                                <h6>5 Year - Fixed Term from</h6>
                                <h2>{insuredFiveYearFixed}%</h2>
                                <h4>Apply for your Best Rate in minutes.</h4>
                                <p className="small-text">Everyone's rate is unique.What's yours?</p>
                                <p>It is our job to get your lowest possible rate. Your rate qualification depends on certain factors, such as credit score and home equity as per regulations.</p>
                                <Button onClick={routeChange}  variant="contained">Get your Best Rate</Button>
                            </div>
                            <div className="mortgage-calculators">
                                <img src={postFiltered.logo} alt="" />
                                <ul>
                                    <li><Link href="/mortgage-payment-calculator">Mortgage Payment Calculator</Link></li>
                                    <li><Link href="/cost-closing-calculator">Closing Cost Calculator</Link></li>
                                    <li><Link href="/affordability-calculator">Affordability Calculator</Link></li>
                                    <li><Link href="/cmhc-insurance-calculator">CMHC Calculator</Link></li>
                                    <li><Link href="/land-transfer-tax-calculator">Land transfer tax Calculator</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default NewBlogDetail;