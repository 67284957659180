import React, { useRef, useState } from 'react' 
import { Container } from "@mui/material"; 
import './closing-cost-calculator.scss';
import Helmet from 'react-helmet';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'

const Closingcostcalculator = function () { 
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Canada Closing Cost Calculator</title>
          <meta name='description' content='Use our Canada Closing Cost calculator to estimate the total cost of buying a home, including legal fees, land transfer taxes, and other expenses. Get an accurate estimate and plan your budget accordingly.' />
          <meta name="keywords" content="closing cost calculator, closing cost calculator toronto, closing cost calculator ontario, closing cost calculator, bc closing cost calculator canada"/>
        </Helmet>
            <section className="c-c-calc sec-padding">
                <Container> 
                <div className="cs-content cs-max-w pb-10">
                    <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Typography color="text.primary">Closing Cost Calculator </Typography>
            </Breadcrumbs> 
            </div>
                <iframe id="idIframe" src="https://ref.rateshop.ca/web/rs-closing-cost-calc.php" width="100%" height="1000" title="cmhc insurance calculator" ></iframe>
                    {/* <iframe src="https://calc.rateshop.ca/purchase.html" width="100%"    
                    height="1700px"
                    title="Mortgage Payment Calculator"></iframe>  */}
                </Container>
            </section>
        </>
    )
}

export default Closingcostcalculator;