import React, { useEffect } from "react";
import './location-detail.scss';
import { Container, Grid } from "@mui/material";
import Blog from "../../../../components/shared/blog/Blog";
import headOffice from "../../../../assets/public/images/head-office.jpg";
import LinkToDetail from "../../../../components/shared/link-to-detail/LinkToDetail";
import { teamMemberOne, teamMemberTwo, teamMemberThree } from "../../../../core/Images";
import { useParams as params } from "react-router-dom";
import { officeLocation } from "../../../../config/office-location";
import { Markup } from 'react-render-markup';


const LocationDetail = function () {
    const { loc_slug } = params();
    // console.log(loc_slug);
    const locFiltered = officeLocation.find(loc => String(loc.slug) === loc_slug);
    return (
        <>
            <section className="page-heading">
                <Container>
                    <h1>{locFiltered.title}</h1>
                </Container>
            </section>
            <section className="locations-map l-m-small">
                <Container>
                    <Grid container>
                        <Grid item md={7}>
                            <iframe src={locFiltered.map} width="100%" height={450} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Grid>
                        <Grid item md={5}>
                            <div className="l-text">
                                <h2>{locFiltered.address}</h2>
                                <div>
                                    <h4>Call</h4>
                                    <ul>
                                        <li><span>Local:</span>{locFiltered.contacts[0]}</li>
                                        <li><span>Toll Free:</span>{locFiltered.contacts[1]}</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>Hours</h4>
                                    <ul>
                                        <li><span>Weekdays:</span>{locFiltered.hoursWeekdays}</li>
                                        <li><span>Weekends:</span>{locFiltered.hoursWeekends}</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="links-to-detail sec-padding">
                <Container>
                    <Grid container>
                        <Grid item sm={6}>
                            <LinkToDetail image={teamMemberOne} heading="Sarah Turck" subHeading="RateShop Advisor" />
                        </Grid>
                        <Grid item sm={6}>
                            <LinkToDetail image={teamMemberTwo} heading="Gabi Mihajlovic" subHeading="RateShop Advisor" />
                        </Grid>
                        <Grid item sm={6}>
                            <LinkToDetail image={teamMemberThree} heading="Raman Saini" subHeading="RateShop Advisor" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="loc-text sec-padding">
                <Container>
                    <Markup markup={locFiltered.description} />
                </Container>
            </section>
        </>
    )
}

export default LocationDetail;