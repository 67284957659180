import React from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";

const SubjectProperties = builder => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const initialValues = {
    perfect_property: "",
    currentAdd: "",
    unit: "",
    street_number: "",
    street: "",
    address_unit: "",
    city: "",
    province: "",
    postal: "",
    purchase_price: "",
    property_tax: "",
    tax_recurring: "",
    property_be_used: "",
    title_type: "",
    date_of_accepted_offer: "",
    month_of_accepted_offer: "",
    year_of_accepted_offer: "",
    mls: "",
    dwelling_type: "",
    dwelling_style: "",
    living_space: "",
    year_built: "",
    heating_type: "",
    garage_type: "",
    total_parking_spaces: "",
    province_of_purchase: "",
    approximate_purchase_price: "",
    estimated_month_of_purchase: "",
    estimated_year_of_purchase: "",
    property_be_used2: "",
    rental_income: "",
    rental_income_recurring: "",
  }
  const formSchema = Yup.object({
    perfect_property: Yup.string().required("This is required field"),
    currentAdd: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    unit: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    street_number: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    street: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    address_unit: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    city: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    province: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    postal: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    purchase_price: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    property_tax: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    tax_recurring: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    property_be_used: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    title_type: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    date_of_accepted_offer: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    month_of_accepted_offer: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    year_of_accepted_offer: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    mls: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    dwelling_type: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    dwelling_style: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    living_space: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    year_built: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    heating_type: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    garage_type: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    total_parking_spaces: Yup.string().when("perfect_property", { is: 'yes', then: () => Yup.string().required("This is required field")}),
    province_of_purchase: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    approximate_purchase_price: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    estimated_month_of_purchase: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    estimated_year_of_purchase: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    property_be_used2: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    rental_income: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
    rental_income_recurring: Yup.string().when("perfect_property", { is: 'no', then: () => Yup.string().required("This is required field")}),
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <><div className="tabbing-of-home">
      <h1>Let's Complete Your Application</h1>
      {JSON.stringify(errors)}
      <form className="form-2" method="post" name="subject" id="subject" onSubmit={handleSubmit}>
        <Grid container>
          <Grid xs={12} md={12} item>
            <h2>Have you found the perfect property?</h2>
            <div className="form-group mb-5">
              <div className="cstm-tick-radiogroup">
                <FormControl>
                  <RadioGroup
                    overlay
                    name="perfect_property"
                    onBlur={handleBlur}
                    value={values.perfect_property}
                    onChange={handleChange}
                    orientation="vertical"
                    sx={{ gap: 2, mt: 1 }}
                  >
                    <Sheet
                      component="label"

                      variant="outlined"
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        bgcolor: 'background.body',
                        gap: 1.5,
                      }}
                    >
                      <Radio
                        value="yes"
                        sx={{
                          mt: -1,
                          mr: -1,
                          mb: 0.5,
                          alignSelf: 'flex-end',
                          '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                        }}
                      />
                      <Typography level="body2">Yes</Typography>
                    </Sheet>
                    <Sheet
                      component="label"

                      variant="outlined"
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        bgcolor: 'background.body',
                        gap: 1.5,
                      }}
                    >
                      <Radio
                        value="no"
                        sx={{
                          mt: -1,
                          mr: -1,
                          mb: 0.5,
                          alignSelf: 'flex-end',
                          '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                        }}
                      />
                      <Typography level="body2">No</Typography>
                    </Sheet>
                  </RadioGroup>
                  {touched.perfect_property && <p>{errors.perfect_property}</p>}
                </FormControl>
              </div>
            </div>
            {/* <!--yes property--> */}
            {values.perfect_property && values.perfect_property === 'yes' &&
              <div className="step-2-1 yesProp" >
              <div className="mb-10 address-qstn">
                <h2>Where is this property located?</h2>
              </div>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <div className="form-group">
                    <div className="cant-find-input">
                      <Input type="text" id="currentAddress" className="form-control pac-target-input" name="currentAdd" onBlur={handleBlur} value={values.currentAdd} onChange={handleChange} placeholder="Enter a location" />
                      {touched.currentAdd && <p>{errors.currentAdd}</p>}
                    </div>
                  </div>
                  <div className="form-group">
                    <Grid container>
                      <Grid xs={12} md={6} item>
                        <FormControl>
                          <FormLabel>Unit</FormLabel>
                          <Input name="unit"
                                 onBlur={handleBlur}
                                 value={values.unit}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.unit && <p>{errors.unit}</p>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-added ">
                    <Grid container>
                      <Grid xs={12} item className=" enter-address">Enter Address Manually</Grid>
                      <Grid xs={12} md={3} item>
                        <FormControl>
                          <FormLabel>Street Number</FormLabel>
                          <Input name="street_number"
                                 onBlur={handleBlur}
                                 value={values.street_number}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.street_number && <p>{errors.street_number}</p>}
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <FormControl>
                          <FormLabel>Street Name</FormLabel>
                          <Input name="street"
                                 onBlur={handleBlur}
                                 value={values.street}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.street && <p>{errors.street}</p>}
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={3} item>
                        <FormControl>
                          <FormLabel>Unit</FormLabel>
                          <Input name="address_unit"
                                 onBlur={handleBlur}
                                 value={values.address_unit}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.address_unit && <p>{errors.address_unit}</p>}
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={5} item>
                        <FormControl>
                          <FormLabel>City</FormLabel>
                          <Input name="city"
                                 onBlur={handleBlur}
                                 value={values.city}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.city && <p>{errors.city}</p>}
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <div className="form-group">
                          <label>Province</label>
                          <Select
                            name="province"
                            onClose={() => {setFieldTouched('province')}}
                            value={values.province}
                            onChange={(e, newValue) => {setFieldValue('province', newValue)}}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="">Please Choose</Option>
                            <Option value="Alberta">Alberta</Option>
                            <Option value="British Columbia">British Columbia</Option>
                            <Option value="Manitoba">Manitoba</Option>
                            <Option value="New Brunswick">New Brunswick</Option>
                            <Option value="Newfoundland and Labrador">Newfoundland &amp; Labrador</Option>
                            <Option value="Northwest Territories">Northwest Territories</Option>
                            <Option value="Nova Scotia">Nova Scotia</Option>
                            <Option value="Nunavut">Nunavut</Option>
                            <Option value="Ontario">Ontario</Option>
                            <Option value="Prince Edward Island">Prince Edward Island</Option>
                            <Option value="Quebec">Quebec</Option>
                            <Option value="Saskatchewan">Saskatchewan</Option>
                            <Option value="Yukon">Yukon</Option>
                          </Select>
                          {touched.province && <p>{errors.province}</p>}
                        </div>
                      </Grid>
                      <Grid xs={12} md={3} item>
                        <FormControl>
                          <FormLabel>Postal Code</FormLabel>
                          <Input name="postal"
                                 onBlur={handleBlur}
                                 value={values.postal}
                                 onChange={handleChange}
                                 placeholder=" " />
                          {touched.postal && <p>{errors.postal}</p>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} item>
                  <h2>Property purchase details</h2>
                </Grid>
                <Grid xs={12} md={6} item>
                  <FormControl>
                    <FormLabel>Purchase Price</FormLabel>
                    <Input
                      name="purchase_price"
                      onBlur={handleBlur}
                      value={values.purchase_price}
                      onChange={handleChange}
                      placeholder="0"
                      startDecorator="$"
                    />
                    {touched.purchase_price && <p>{errors.purchase_price}</p>}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6} item className="form-group">
                  <label>
                    Property tax
                  </label>
                  <div className="tw-inputs">
                    <Input name="property_tax"
                           onBlur={handleBlur}
                           value={values.property_tax}
                           onChange={handleChange}
                           placeholder=" " startDecorator="$" />
                    {touched.property_tax && <p>{errors.property_tax}</p>}
                    <Select
                      name="tax_recurring"
                      onClose={() => {setFieldTouched('tax_recurring')}}
                      value={values.tax_recurring}
                      onChange={(e, newValue) => {setFieldValue('tax_recurring', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="monthly">Monthly</Option>
                      <Option value="yearly" selected="">Yearly</Option>
                    </Select>
                    {touched.tax_recurring && <p>{errors.tax_recurring}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>How will this property be used?</label>
                    <Select
                      name="property_be_used"
                      onClose={() => {setFieldTouched('property_be_used')}}
                      value={values.property_be_used}
                      onChange={(e, newValue) => {setFieldValue('property_be_used', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Please Select</Option>
                      <Option value="Owner occupied">Owner occupied</Option>
                      <Option value="Owner-Occupied and Rental">Owner occupied w/rental</Option>
                      <Option value="Second Home">Secondary residence</Option>
                      <Option value="Rental">Rental</Option>
                      <Option value="Selling/Sold">Selling/Sold</Option>
                    </Select>
                    {touched.property_be_used && <p>{errors.property_be_used}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>Title type</label>
                    <Select
                      name="title_type"
                      onClose={() => {setFieldTouched('title_type')}}
                      value={values.title_type}
                      onChange={(e, newValue) => {setFieldValue('title_type', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Select Title</Option>
                      <Option value="Freehold">Freehold</Option>
                      <Option value="Leasehold">Leasehold</Option>
                      <Option value="Condo / Strata">Condo / Strata</Option>
                      <Option value="Indian Reserve">Indian Reserve</Option>
                      <Option value="Other...">Other...</Option>
                    </Select>
                    {touched.title_type && <p>{errors.title_type}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>Date of accepted offer</label>
                    <div className="tw-inputs thre-inputs">
                      <Select
                        name="date_of_accepted_offer"
                        onClose={() => {setFieldTouched('date_of_accepted_offer')}}
                        value={values.date_of_accepted_offer}
                        onChange={(e, newValue) => {setFieldValue('date_of_accepted_offer', newValue)}}
                        slotProps={{
                          button: {
                            id: 'select-button',
                            'aria-labelledby': 'select-label select-button',
                          }
                        }}
                      >
                        <Option value="">Day</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                        <Option value="8">8</Option>
                        <Option value="9">9</Option>
                        <Option value="10">10</Option>
                        <Option value="11">11</Option>
                        <Option value="12">12</Option>
                        <Option value="13">13</Option>
                        <Option value="14">14</Option>
                        <Option value="15">15</Option>
                        <Option value="16">16</Option>
                        <Option value="17">17</Option>
                        <Option value="18">18</Option>
                        <Option value="19">19</Option>
                        <Option value="20">20</Option>
                        <Option value="21">21</Option>
                        <Option value="22">22</Option>
                        <Option value="23">23</Option>
                        <Option value="24">24</Option>
                        <Option value="25">25</Option>
                        <Option value="26">26</Option>
                        <Option value="27">27</Option>
                        <Option value="28">28</Option>
                        <Option value="29">29</Option>
                        <Option value="30">30</Option>
                        <Option value="31">31</Option>
                      </Select>
                      {touched.date_of_accepted_offer && <p>{errors.date_of_accepted_offer}</p>}
                      <Select
                        name="month_of_accepted_offer"
                        onClose={() => {setFieldTouched('month_of_accepted_offer')}}
                        value={values.month_of_accepted_offer}
                        onChange={(e, newValue) => {setFieldValue('month_of_accepted_offer', newValue)}}
                        slotProps={{
                          button: {
                            id: 'select-button',
                            'aria-labelledby': 'select-label select-button',
                          }
                        }}
                      >
                        <Option value="">Month</Option>
                        <Option value="01">January</Option>
                        <Option value="02">February</Option>
                        <Option value="03">March</Option>
                        <Option value="04">April</Option>
                        <Option value="05">May</Option>
                        <Option value="06">June</Option>
                        <Option value="07">July</Option>
                        <Option value="08">August</Option>
                        <Option value="09">September</Option>
                        <Option value="10">October</Option>
                        <Option value="11">November</Option>
                        <Option value="12">December</Option>
                      </Select>
                      {touched.month_of_accepted_offer && <p>{errors.month_of_accepted_offer}</p>}
                      <Select
                        name="year_of_accepted_offer"
                        onClose={() => {setFieldTouched('year_of_accepted_offer')}}
                        value={values.year_of_accepted_offer}
                        onChange={(e, newValue) => {setFieldValue('year_of_accepted_offer', newValue)}}
                        slotProps={{
                          button: {
                            id: 'select-button',
                            'aria-labelledby': 'select-label select-button',
                          }
                        }}
                      >
                        <Option value="">Year</Option>
                        <Option value="2023">2023</Option>
                        <Option value="2022">2022</Option>
                        <Option value="2021">2021</Option>
                        <Option value="2020">2020</Option>
                        <Option value="2019">2019</Option>
                        <Option value="2018">2018</Option>
                        <Option value="2017">2017</Option>
                        <Option value="2016">2016</Option>
                        <Option value="2015">2015</Option>
                        <Option value="2014">2014</Option>
                        <Option value="2013">2013</Option>
                        <Option value="2012">2012</Option>
                        <Option value="2011">2011</Option>
                        <Option value="2010">2010</Option>
                        <Option value="2009">2009</Option>
                        <Option value="2008">2008</Option>
                        <Option value="2007">2007</Option>
                        <Option value="2006">2006</Option>
                        <Option value="2005">2005</Option>
                        <Option value="2004">2004</Option>
                        <Option value="2003">2003</Option>
                        <Option value="2002">2002</Option>
                        <Option value="2001">2001</Option>
                        <Option value="2000">2000</Option>
                        <Option value="1999">1999</Option>
                        <Option value="1998">1998</Option>
                        <Option value="1997">1997</Option>
                        <Option value="1996">1996</Option>
                        <Option value="1995">1995</Option>
                        <Option value="1994">1994</Option>
                        <Option value="1993">1993</Option>
                        <Option value="1992">1992</Option>
                        <Option value="1991">1991</Option>
                        <Option value="1990">1990</Option>
                        <Option value="1989">1989</Option>
                        <Option value="1988">1988</Option>
                        <Option value="1987">1987</Option>
                        <Option value="1986">1986</Option>
                        <Option value="1985">1985</Option>
                        <Option value="1984">1984</Option>
                        <Option value="1983">1983</Option>
                        <Option value="1982">1982</Option>
                        <Option value="1981">1981</Option>
                        <Option value="1980">1980</Option>
                        <Option value="1979">1979</Option>
                        <Option value="1978">1978</Option>
                        <Option value="1977">1977</Option>
                        <Option value="1976">1976</Option>
                        <Option value="1975">1975</Option>
                        <Option value="1974">1974</Option>
                        <Option value="1973">1973</Option>
                        <Option value="1972">1972</Option>
                        <Option value="1971">1971</Option>
                        <Option value="1970">1970</Option>
                        <Option value="1969">1969</Option>
                        <Option value="1968">1968</Option>
                        <Option value="1967">1967</Option>
                        <Option value="1966">1966</Option>
                        <Option value="1965">1965</Option>
                        <Option value="1964">1964</Option>
                        <Option value="1963">1963</Option>
                        <Option value="1962">1962</Option>
                        <Option value="1961">1961</Option>
                        <Option value="1960">1960</Option>
                        <Option value="1959">1959</Option>
                        <Option value="1958">1958</Option>
                        <Option value="1957">1957</Option>
                        <Option value="1956">1956</Option>
                        <Option value="1955">1955</Option>
                        <Option value="1954">1954</Option>
                        <Option value="1953">1953</Option>
                      </Select>
                      {touched.year_of_accepted_offer && <p>{errors.year_of_accepted_offer}</p>}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} item>  <h2>Property details</h2></Grid>
                <Grid xs={12} md={12} item>
                  <FormControl>
                    <FormLabel> MLS® number</FormLabel>
                    <Input
                      name="mls"
                      onBlur={handleBlur}
                      value={values.mls}
                      onChange={handleChange}
                      placeholder="0"
                      startDecorator="#"
                    />
                    {touched.mls && <p>{errors.mls}</p>}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6} item>
                  <label>
                    Dwelling type
                  </label>
                  <div className="form-group">
                    <Select
                      name="dwelling_type"
                      onClose={() => {setFieldTouched('dwelling_type')}}
                      value={values.dwelling_type}
                      onChange={(e, newValue) => {setFieldValue('dwelling_type', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Select Dwelling configuration...</Option>
                      <Option value="Detached">Detached</Option>
                      <Option value="Semi-Detached">Semi-Detached</Option>
                      <Option value="Duplex-Detached">Duplex-Detached</Option>
                      <Option value="Duplex-Semi-Detached">Duplex-Semi-Detached</Option>
                      <Option value="Row Housing">Row Housing</Option>
                      <Option value="Apartment Low Rise">Apartment Low Rise</Option>
                      <Option value="Apartment High Rise">Apartment High Rise</Option>
                      <Option value="Mobile">Mobile</Option>
                      <Option value="Trip Plex - Detached">Trip Plex - Detached</Option>
                      <Option value="Trip Plex - Semi-Detached">Trip Plex - Semi-Detached</Option>
                      <Option value="Stacked">Stacked</Option>
                      <Option value="Modular Home - Detached">Modular Home - Detached</Option>
                      <Option value="Modular Home - Semi-Detached">Modular Home - Semi-Detached</Option>
                      <Option value="Four Plex - Detached">Four Plex - Detached</Option>
                    </Select>
                    {touched.dwelling_type && <p>{errors.dwelling_type}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>
                      Dwelling style
                    </label>
                    <Select
                      name="dwelling_style"
                      onClose={() => {setFieldTouched('dwelling_style')}}
                      value={values.dwelling_style}
                      onChange={(e, newValue) => {setFieldValue('dwelling_style', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Dwelling Style</Option>
                      <Option value="One storey">One storey</Option>
                      <Option value="Bi-level">Bi-level</Option>
                      <Option value="Two storey">Two storey</Option>
                      <Option value="Split level">Split level</Option>
                      <Option value="Storey and a half">Storey and a half</Option>
                      <Option value="Three storey">Three storey</Option>
                      <Option value="Four storey">Four storey</Option>
                      <Option value="Five storey">Five storey</Option>
                      <Option value="Six storey">Six storey</Option>
                      <Option value="Seven storey">Seven storey</Option>
                      <Option value="Eight storey">Eight storey</Option>
                      <Option value="Nine storey">Nine storey</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                    {touched.dwelling_style && <p>{errors.dwelling_style}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <FormControl>
                    <FormLabel> Living Space(Sqft.)</FormLabel>
                    <Input name="living_space"
                           onBlur={handleBlur}
                           value={values.living_space}
                           onChange={handleChange}
                           placeholder=" " />
                    {touched.living_space && <p>{errors.living_space}</p>}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>
                      Year Built
                    </label>
                    <Select
                      name="year_built"
                      onClose={() => {setFieldTouched('year_built')}}
                      value={values.year_built}
                      onChange={(e, newValue) => {setFieldValue('year_built', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Year</Option>
                      <Option value="2023">2023</Option>
                      <Option value="2022">2022</Option>
                      <Option value="2021">2021</Option>
                      <Option value="2020">2020</Option>
                      <Option value="2019">2019</Option>
                      <Option value="2018">2018</Option>
                      <Option value="2017">2017</Option>
                      <Option value="2016">2016</Option>
                      <Option value="2015">2015</Option>
                      <Option value="2014">2014</Option>
                      <Option value="2013">2013</Option>
                      <Option value="2012">2012</Option>
                      <Option value="2011">2011</Option>
                      <Option value="2010">2010</Option>
                      <Option value="2009">2009</Option>
                      <Option value="2008">2008</Option>
                      <Option value="2007">2007</Option>
                      <Option value="2006">2006</Option>
                      <Option value="2005">2005</Option>
                      <Option value="2004">2004</Option>
                      <Option value="2003">2003</Option>
                      <Option value="2002">2002</Option>
                      <Option value="2001">2001</Option>
                      <Option value="2000">2000</Option>
                      <Option value="1999">1999</Option>
                      <Option value="1998">1998</Option>
                      <Option value="1997">1997</Option>
                      <Option value="1996">1996</Option>
                      <Option value="1995">1995</Option>
                      <Option value="1994">1994</Option>
                      <Option value="1993">1993</Option>
                    </Select>
                    {touched.year_built && <p>{errors.year_built}</p>}
                  </div>
                </Grid>
                <Grid xs={12} md={6} item>
                  <div className="form-group">
                    <label>
                      Heating type                                                 </label>
                    <Select
                      name="heating_type"
                      onClose={() => {setFieldTouched('heating_type')}}
                      value={values.heating_type}
                      onChange={(e, newValue) => {setFieldValue('heating_type', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Select Heat...</Option>
                      <Option value="Electric Baseboard">Electric Baseboard</Option>
                      <Option value="Forced Air Gas/Oil/Electric">Forced Air Gas/Oil/Electric</Option>
                      <Option value="Hot Water Heating">Hot Water Heating</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                    {touched.heating_type && <p>{errors.heating_type}</p>}
                  </div>
                </Grid>
                <Grid xs={12} xl={4} md={6} item>
                  <div className="form-group">
                    <label>
                      Garage type
                    </label>
                    <Select
                      name="garage_type"
                      onClose={() => {setFieldTouched('garage_type')}}
                      value={values.garage_type}
                      onChange={(e, newValue) => {setFieldValue('garage_type', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Select Garage Type...</Option>
                      <Option value="Attached">Attached</Option>
                      <Option value="Detached">Detached</Option>
                    </Select>
                    {touched.garage_type && <p>{errors.garage_type}</p>}
                  </div>
                </Grid>
                <Grid xs={12} xl={2} lg={6} item>
                  <FormControl>
                    <FormLabel>Total Parking Spaces</FormLabel>
                    <Input name="total_parking_spaces"
                           onBlur={handleBlur}
                           value={values.total_parking_spaces}
                           onChange={handleChange}
                           placeholder=" " />
                    {touched.total_parking_spaces && <p>{errors.total_parking_spaces}</p>}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            }
          </Grid>
        </Grid>
        {/* <!-- No --> */}
        {values.perfect_property && values.perfect_property === 'no' &&
          <div className="info step-2-2 noProp" >
            <h2>Future property information</h2>
            <Grid container>
              <Grid xs={12} md={12} item>
                <div className="form-group">
                  <label>Province of purchase</label>
                  <Select
                    name="province_of_purchase"
                    onClose={() => {setFieldTouched('province_of_purchase')}}
                    value={values.province_of_purchase}
                    onChange={(e, newValue) => {setFieldValue('province_of_purchase', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="">Select Province</Option>
                    <Option value="Alberta">Alberta</Option>
                    <Option value="British Columbia">British Columbia</Option>
                    <Option value="Manitoba">Manitoba</Option>
                    <Option value="New Brunswick">New Brunswick</Option>
                    <Option value="Newfoundland and Labrador">Newfoundland &amp; Labrador</Option>
                    <Option value="Northwest Territories">Northwest Territories</Option>
                    <Option value="Nova Scotia">Nova Scotia</Option>
                    <Option value="Nunavut">Nunavut</Option>
                    <Option value="Ontario">Ontario</Option>
                    <Option value="Prince Edward Island">Prince Edward Island</Option>
                    <Option value="Quebec">Quebec</Option>
                    <Option value="Saskatchewan">Saskatchewan</Option>
                    <Option value="Yukon">Yukon</Option>
                  </Select>
                  {touched.province_of_purchase && <p>{errors.province_of_purchase}</p>}
                </div>
              </Grid>
              <Grid xs={12} md={6} item>
                <FormControl>
                  <FormLabel> Approximate purchase price</FormLabel>
                  <Input name="approximate_purchase_price"
                         onBlur={handleBlur}
                         value={values.approximate_purchase_price}
                         onChange={handleChange}
                         placeholder=" " />
                  {touched.approximate_purchase_price && <p>{errors.approximate_purchase_price}</p>}
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} item>
                <div className="form-group">
                  <label>
                    Estimated date of purchase
                  </label>
                  <div className="tw-inputs">
                    <Select
                      name="estimated_month_of_purchase"
                      onClose={() => {setFieldTouched('estimated_month_of_purchase')}}
                      value={values.estimated_month_of_purchase}
                      onChange={(e, newValue) => {setFieldValue('estimated_month_of_purchase', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Month</Option>
                      <Option value="01">January</Option>
                      <Option value="02">February</Option>
                      <Option value="03">March</Option>
                      <Option value="04">April</Option>
                      <Option value="05">May</Option>
                      <Option value="06">June</Option>
                      <Option value="07">July</Option>
                      <Option value="08">August</Option>
                      <Option value="09">September</Option>
                      <Option value="10">October</Option>
                      <Option value="11">November</Option>
                      <Option value="12">December</Option>
                    </Select>
                    {touched.estimated_year_of_purchase && <p>{errors.estimated_year_of_purchase}</p>}
                    <Select
                      name="estimated_month_of_purchase"
                      onClose={() => {setFieldTouched('estimated_year_of_purchase')}}
                      value={values.estimated_year_of_purchase}
                      onChange={(e, newValue) => {setFieldValue('estimated_year_of_purchase', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="">Year</Option>
                      <Option value="2023">2023</Option>
                      <Option value="2024">2024</Option>
                      <Option value="2025">2025</Option>
                      <Option value="2026">2026</Option>
                      <Option value="2027">2027</Option>
                      <Option value="2028">2028</Option>
                      <Option value="2029">2029</Option>
                      <Option value="2030">2030</Option>
                      <Option value="2031">2031</Option>
                      <Option value="2032">2032</Option>
                      <Option value="2033">2033</Option>
                      <Option value="2034">2034</Option>
                      <Option value="2035">2035</Option>
                      <Option value="2036">2036</Option>
                      <Option value="2037">2037</Option>
                      <Option value="2038">2038</Option>
                      <Option value="2039">2039</Option>
                      <Option value="2040">2040</Option>
                      <Option value="2041">2041</Option>
                      <Option value="2042">2042</Option>
                      <Option value="2043">2043</Option>
                      <Option value="2044">2044</Option>
                      <Option value="2045">2045</Option>
                      <Option value="2046">2046</Option>
                      <Option value="2047">2047</Option>
                      <Option value="2048">2048</Option>
                      <Option value="2049">2049</Option>
                      <Option value="2050">2050</Option>
                      <Option value="2051">2051</Option>
                      <Option value="2052">2052</Option>
                      <Option value="2053">2053</Option>
                      <Option value="2054">2054</Option>
                      <Option value="2055">2055</Option>
                      <Option value="2056">2056</Option>
                      <Option value="2057">2057</Option>
                      <Option value="2058">2058</Option>
                      <Option value="2059">2059</Option>
                      <Option value="2060">2060</Option>
                      <Option value="2061">2061</Option>
                      <Option value="2062">2062</Option>
                      <Option value="2063">2063</Option>
                      <Option value="2064">2064</Option>
                      <Option value="2065">2065</Option>
                      <Option value="2066">2066</Option>
                      <Option value="2067">2067</Option>
                      <Option value="2068">2068</Option>
                      <Option value="2069">2069</Option>
                      <Option value="2070">2070</Option>
                      <Option value="2071">2071</Option>
                      <Option value="2072">2072</Option>
                      <Option value="2073">2073</Option>
                      <Option value="2074">2074</Option>
                      <Option value="2075">2075</Option>
                      <Option value="2076">2076</Option>
                      <Option value="2077">2077</Option>
                      <Option value="2078">2078</Option>
                      <Option value="2079">2079</Option>
                      <Option value="2080">2080</Option>
                      <Option value="2081">2081</Option>
                      <Option value="2082">2082</Option>
                      <Option value="2083">2083</Option>
                      <Option value="2084">2084</Option>
                      <Option value="2085">2085</Option>
                      <Option value="2086">2086</Option>
                      <Option value="2087">2087</Option>
                      <Option value="2088">2088</Option>
                      <Option value="2089">2089</Option>
                      <Option value="2090">2090</Option>
                      <Option value="2091">2091</Option>
                      <Option value="2092">2092</Option>
                      <Option value="2093">2093</Option>
                    </Select>
                    {touched.estimated_year_of_purchase && <p>{errors.estimated_year_of_purchase}</p>}
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={6} item>
                <div className="form-group">
                  <label>
                    How will this property be used?
                  </label>
                  <Select
                    name="property_be_used2"
                    onClose={() => {setFieldTouched('property_be_used2')}}
                    value={values.property_be_used2}
                    onChange={(e, newValue) => {setFieldValue('property_be_used2', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="">Please Select</Option>
                    <Option value="Owner occupied">Owner occupied</Option>
                    <Option value="Owner-Occupied and Rental">Owner occupied w/rental</Option>
                    <Option value="Second Home">Secondary residence</Option>
                    <Option value="Rental">Rental</Option>
                    <Option value="Selling/Sold">Selling/Sold</Option>
                  </Select>
                  {touched.property_be_used2 && <p>{errors.property_be_used2}</p>}
                </div>
              </Grid>
              <Grid xs={12} md={6} item>
                <div className="form-group">
                  <label>Rental income</label>
                  <div className="tw-inputs">
                    <FormControl>
                      <Input name="rental_income"
                             onBlur={handleBlur}
                             value={values.rental_income}
                             onChange={handleChange}
                             placeholder=" " startDecorator="$" />
                      {touched.rental_income && <p>{errors.rental_income}</p>}
                    </FormControl>
                    <Select
                      name="rental_income_recurring"
                      onClose={() => {setFieldTouched('rental_income_recurring')}}
                      value={values.rental_income_recurring}
                      onChange={(e, newValue) => {setFieldValue('rental_income_recurring', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="monthly" selected="">Monthly</Option>
                      <Option value="yearly">Yearly</Option>
                    </Select>
                    {touched.rental_income_recurring && <p>{errors.rental_income_recurring}</p>}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        }

        <div className="button-grp d-flex align-items-start">
          <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
            Previous
          </Button>
          <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
            Finished with Liabilities
          </Button>
        </div>
      </form >
    </div >
    </>
  );
};

export default SubjectProperties;
