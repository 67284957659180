import { default as lenderImg35 } from "../assets/public/images/lenders/lenders-35.svg";
import lenderImg36 from "../assets/public/images/lenders/lenders-36.png";

export const officeLocation = [
    {
        title: "Head Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.7354228333816!2d-79.6930255238808!3d43.73682287109828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c5233164c69%3A0x47c17de82a8628b9!2s6%20Indell%20Ln%2C%20Brampton%2C%20ON%20L6T%203Y3%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352201086!5m2!1sen!2sin",
        address: "6 Indell Ln, Brampton, ON L6T 3Y3, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "head-office",
        description: "<h2>Maecenas non bibendum nibh. Suspendisse id elit condimentum, mollis ligula nec, tempor turpis.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "Customer Service",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.5225302777567!2d-79.86369252389176!3d43.53314857110815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b6ee2fae07d17%3A0x2554b8997cfbd17a!2s1228%20Main%20St%20E%2C%20Milton%2C%20ON%20L9T%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352277966!5m2!1sen!2sin",
        address: "1228 Main St E, Milton, ON L9T, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "customer-service",
        description: "<h2>Ut eleifend quam arcu, in pharetra est vehicula id. Duis imperdiet suscipit erat, et iaculis ante placerat id.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "British Columbia Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.5483251724113!2d-124.80377712357034!3d49.2280924713847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5488f73524007a25%3A0x8964e6f0cd93607!2s4529%20Melrose%20St%2C%20Port%20Alberni%2C%20BC%20V9Y%201K7%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352804299!5m2!1sen!2sin",
        address: "4529 MELROSE STREET, PORT ALBERN BC V9Y 1K7 CANADA",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "british-columbia-office",
        description: "<h2>Pellentesque nec purus condimentum, egestas metus nec, dapibus tellus.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "Alberta Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.874346328215!2d-113.49047452330744!3d53.54215777234675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a022450fa1ca95%3A0xe95a394e228d927!2s10123%2099%20St%20NW%2C%20Edmonton%2C%20AB%20T5J%203H1%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352335574!5m2!1sen!2sin",
        address: "10123 99 St NW #1200, Edmonton, AB T5J 3H1, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "alberta-office",
        description: "<h2>Curabitur nulla velit, auctor sit amet mollis lacinia, imperdiet a risus.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "Saskatchewan Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.471586448918!2d-104.59603192349738!3d50.450942471592185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1e4ad8e02417%3A0x4dc31fa879881d1!2s855%20Arcola%20Ave%2C%20Regina%2C%20SK%20S4N%200S9%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352377977!5m2!1sen!2sin",
        address: "855 Arcola Ave suite 300, Regina, SK S4N 0S9, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "saskatchewan-office",
        description: "<h2>Aliquam porta purus at urna vehicula mattis. Nam lobortis luctus nulla at condimentum.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "Winnepeg Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.473463041316!2d-97.15108692353101!3d49.88991387149058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea73fd422c1a25%3A0xe222f7b72b628e0!2s225%20Vaughan%20St%2C%20Winnipeg%2C%20MB%20R3C%201T7%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352431559!5m2!1sen!2sin",
        address: "225 Vaughan St #305, Winnipeg, MB R3C 0E7, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "winnepeg-office",
        description: "<h2>Proin scelerisque tempor tellus ac luctus. Vestibulum pharetra ac enim id condimentum.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "New Brunswick Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2772.4418745489306!2d-66.72102372375726!3d45.98240597108716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ca41802a07dfa3b%3A0x1e4ff38520e5a0a0!2s7%20Emerald%20St%2C%20Fredericton%2C%20NB%20E3G%207V3%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352483999!5m2!1sen!2sin",
        address: "7 Emerald St, Fredericton, NB E3G 7V3, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "new-brunswick-office",
        description: "<h2>Mauris ut vestibulum ligula. Nunc blandit blandit accumsan. Nullam a iaculis neque.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    },
    {
        title: "Prince Edward Islands Office",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2758.113825366413!2d-63.14957122374128!3d46.26784217109844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5fad501b55b477%3A0xa64950d7d8a91cd2!2s7%20Babineau%20Avenue%2C%20Charlottetown%2C%20PE%20C1A%200C9%2C%20Canada!5e0!3m2!1sen!2sin!4v1682352521445!5m2!1sen!2sin",
        address: "7 Babineau Avenue, Charlottetown, PE C1A 0C9, Canada",
        contacts: ["416-827-2626", "800-264-5855"],
        hoursWeekdays: "9am - 6pm",
        hoursWeekends: "10am - 6pm",
        slug: "prince-edward-islands-office",
        description: "<h2>Donec vitae nunc malesuada, laoreet metus eget, aliquam risus. Aliquam placerat augue magna, id porttitor diam feugiat et.</h2><p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p><p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p><p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p><p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p><p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>",
    }
];