// Front End
import Home from './frontend/home/Home';
import Loans from './frontend/loans/Loans';
import CreditCards from './frontend/credit-cards/CreditCards';
import Mortgages from './frontend/mortgages/Mortgages';
import Sitemap from './frontend/sitemap/sitemap';
import Blogs from './frontend/blogs/Blogs';
import BlogsCategory from './frontend/blog-category/Blog-category';
import BlogDetail from './frontend/blog-detail/Blog-detail';
import Banking from './frontend/banking/banking';
import Investments from './frontend/investments/Investments';
import Insurance from './frontend/insurance/Insurance';
import Contact from './frontend/contact/Contact';
import MoneyTransfers from './frontend/money-transfers/MoneyTransfers';
import CreditScore from './frontend/credit-score/CreditScore';
import Cards from './frontend/cards/Cards';
import Eligibility from './frontend/Eligibility/Eligibility';
import Result from './frontend/result/Result';
import Result2 from './frontend/result2/Result2';
import AgentsDirectory from './frontend/agents-directory/AgentsDirectory';
import RateshopMortgage from './frontend/rateshop-mortgage/RateshopMortgage';
import RateshopMortgageLocation from './frontend/rateshop-mortgage-location/RateshopMortgage-location';
import RateshopMortgageLocationCity from './frontend/rateshop-mortgage-location-city/RateshopMortgage-location-city.js';
import CommercialMortgage from './frontend/commercial-mortgages/commercial-mortgage';
import MortgageProgram from './frontend/mortgage-program/mortgage-program.js';
// Apply Now
import ApplyNow from './apply-now/apply-now';
import ApplyNowWidget from './apply-now-widget/apply-now';

// Sweaptake
import Sweaptake from './sweaptake/sweaptake';

// Not Found
import NotFound from './not-found/NotFound';

// Application
import ApplicationPurchase from './application/purchase/purchase';
import ApplicationAddresses from './application/addresses/addresses';
import ApplicationApplicant from './application/applicant/applicant';
import ApplicationConcent from './application/concent/concent';
import ApplicationDownpayment from './application/down-payment/down-payment';
import ApplicationIncome from './application/income/income';
import ApplicationLiabilities from './application/liabilties/liabilities';
import ApplicationProperties from './application/properties/properties';
import SubjectProperties from './application/subject-properties/subject-properties';
import LocationDetailnw from './frontend/location-detail-nw/LocationDetail.js';
import JoinUs from './frontend/join-us/Join-us.js';
import JoinUsNew from './frontend/join-us/join-us-new.js';
import Packages from './frontend/packages/packages.js';

// Privacy Policy
import PrivacyPolicy from './frontend/privacy-policy/privacy-policy.js';
import LegalDisclaimer from './frontend/legal-disclaimer/legal-disclaimer.js'


import BlogDetailNw from './frontend/blog-details-nw/Blog-details.js';

export {
    Home,
    Loans,
    CreditCards,
    Mortgages,
    Blogs,
    BlogsCategory,
    BlogDetail,
    Banking,
    Investments,
    Insurance,
    Contact,
    MoneyTransfers,
    CreditScore,
    Cards,
    Eligibility,
    Result,
    Result2,
    AgentsDirectory,
    RateshopMortgage,
    RateshopMortgageLocation,
    RateshopMortgageLocationCity,
    ApplyNow,
    ApplyNowWidget,
    Sweaptake,
    NotFound,
    CommercialMortgage,
    MortgageProgram,
    // Application
    ApplicationPurchase,
    ApplicationAddresses,
    ApplicationApplicant,
    ApplicationConcent,
    ApplicationDownpayment,
    ApplicationIncome,
    ApplicationLiabilities,
    ApplicationProperties,
    SubjectProperties,
    Sitemap,
    LocationDetailnw,
    JoinUs,
    Packages,
    PrivacyPolicy,LegalDisclaimer,
    BlogDetailNw,
    JoinUsNew
}

